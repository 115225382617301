//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown, sysConfigEdit } from '@/api/modular/system/configManage'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      formLoading: true,
      groupCodeList: [],
      editDisabled: false,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    edit (record) {
      this.visible = true
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            id: record.id,
            name: record.name,
            code: record.code,
            groupCode: record.groupCode,
            sysFlag: record.sysFlag,
            value: record.value,
            remark: record.remark
          }
        )
      }, 100)
      // eslint-disable-next-line eqeqeq
      if (record.sysFlag == 'Y') {
         this.editDisabled = true
      }
      this.sysDictTypeDropDown()
    },

    /**
     * 獲取所屬分類
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'consts_type' }).then((res) => {
        this.groupCodeList = res.data
        this.formLoading = false
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysConfigEdit(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.editDisabled = false
    }
  }
}
